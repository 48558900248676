import { Component, OnInit, Inject, KeyValueDiffers } from '@angular/core';
import moment from 'moment';
declare var angular: angular.IAngularStatic;
import strings from '../../../assets/locale-en.json';
import settings from '../../../config.json';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ToastService } from '../core/services/toast.service';
import { AuthorizedService } from '../core/services/authorized.service';
import { AircraftService } from '../core/services/aircraft.service';
import { SubscriptionService } from '../core/services/subscription.service';
import { OperatorService } from '../core/services/operator.service';
import { Config } from '../core/config';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { MediadriveService } from '../core/services/mediadrive.service';
import { SyncPreferenceChangeDialogComponent } from './sync-preference-change-dialog.component';
import * as _ from "lodash";
import { FormGroup, FormControl } from '@angular/forms';
import { AircraftGeneralConfirmDialogComponent } from './aircraft-general-confirm-dialog.component';
import { LimitedServicesDialogComponent } from './limited-services-dialog.component';
import { AircraftServiceEnableDialogComponent } from './aircraft-service-enable-dialog.component';
import { CoreService } from '../core/services/core.service';
import { SubscriptionPlanChangeDialogComponent } from './subscription-plan-change-dialog.component';

@Component({
  //selector: 'aircraft-dialog',
  templateUrl: './aircraft-dialog.component.html',
  styleUrls: ['./aircraft.less', '../layout/tab-dialog.less']
})

export class AircraftDialogComponent implements OnInit {

  public validation: any = settings.configuration.validation.rules;
  public placeholder: string = 'assets/icons/aircraft_default.png';
  public image: string = settings.configuration.env.apiUrl + 'images/fleet/';
  private hasNewImage: boolean = false;
  private imageDeleted: boolean = false;
  public hasContentDiskSpace: boolean = false;
  public configJSONParsed: boolean = false;
  public timeStamp: string = '?=' + new Date().getTime();
  public dateTime: any = settings.configuration.datetime_formats;
  private currencySymbol: string = settings.configuration.number_formats.currency_sym;
  public configuration: any = null;

  //aircraft data during dialog open
  public aircraft: any = [];
  public currentTab: string = '';
  //private aircraft_id: string = '';
  public operators: any = [];
  public operator_active: boolean = false;
  public hasAircraft: boolean = true;
  public connectivityTypes: any = [];
  public downloadPreferences: any = [];

  //for changing aircraft_id
  private oldId: string = '';
  private originalAircraft: Aircraft = new Aircraft();
  public originalAircraftId: string = '';

  //predefined aircaft manufacurer model pairs
  public manufacturers: any = [];
  public currentManufacturer: string = '';
  public manufacturerModels: any = [];
  public currentModel: string = '';

  // stage clients
  public stageClients: any = [];
  public previousStageClientId: string = '';
  public currentStageClientId: string = '';
  public currentStageClientTitle: string = '';

  // stage installations
  public installationTypes: any = [];
  public currentInstallationType: any = [];
  public currentInstallationId: string = '';

  public isLoadingLinkButton: boolean = false;
  public isLoading: boolean = false;
  public subscriptionPlans = [];
  public loading = [];
  public subscriptionPlanServices = null;
  public configHistoryDetails: any = null;
  public configLoad: boolean = false;
  public downloadConfigId: any;
  private originalService: any = null;
  public selectedSubscriptionPlan: any = [];
  public selectedAssetPlan: any = [];
  public serviceIndex: number = -1;

  public blockUI: boolean = false;
  public showImageOptions: boolean = false;
  public newPassword: string = '';
  public confirmPassword: string = '';
  public aircraftPassword: FormGroup;
  public errors: any = '';
  public configHistory: boolean = false;
  public personalFile: string = '';
  public fileDetails: any;
  public currentMediaStorageSize: any;
  private differ;
  // Limited Services
  public limitedServices: any = [];
  public currentLimitedServiceMovieId: any = '';
  public currentLimitedServiceTVShowId: any = '';
  public showAllLimitedService: boolean = false;
  public serviceId: string = '';

  public currentMovieSelectionType = '';
  public currentTVShowSelectionType = '';
  public previousLimitedServiceMovieId = '';
  public previousLimitedServiceTVShowId = '';
  public dcslVersions: any = [];
  public currentDCSLVersionId: number=2;
  public currentDCSLVersion: any = [];

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    public authorizedService: AuthorizedService,
    private aircraftService: AircraftService,
    private subscriptionService: SubscriptionService,
    private operatorService: OperatorService,
    private mediadriveService: MediadriveService,
    private differs: KeyValueDiffers,
    private config: Config,
    public coreservice: CoreService,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private syncPreferenceChangeDialog: MatDialog,
    public matDialog: MatDialog

  ) {
    this.differ = this.differs.find({}).create();
    this.operators = this.dialogData.operators;
    this.currentTab = this.dialogData.tabName ? this.dialogData.tabName : 'aircraft';

    //for changing aircraft_id
    this.oldId = this.dialogData.aircraft_id;
    this.originalAircraft.aircraft_id = this.dialogData.aircraft_id;
    this.originalAircraftId = this.originalAircraft.aircraft_id;

    //predefined aircaft manufacurer model pairs
    this.currentManufacturer = this.dialogData.manufacturer;
    this.currentModel = this.dialogData.model;
    this.aircraftPassword = new FormGroup({
      newPassword: new FormControl(''),
      confirmPassword: new FormControl(''),
      emails: new FormControl('')
    });
     this.currentLimitedServiceMovieId = 'All';
     this.currentLimitedServiceTVShowId = 'All';

    this.coreservice.changePlan = false;
    this.coreservice.selectPlan = false;
    this.coreservice.editPlan = false;
  }

  ngDoCheck() {
    const change = this.differ.diff(this.currentInstallationType);
    if (change) {
      this.currentMediaStorageSize = ((this.currentInstallationType.mediaStorageSize) / (Math.pow(1024, 2))).toFixed(1);
    }
  }

  onChangeInstallationType() {
    if (!this.dialogData.createNew) {
      var installationIndex: number = _.findIndex(this.installationTypes,
        _.find(this.installationTypes, { id: this.currentInstallationId }));
      let installationTypeName: string = this.installationTypes[installationIndex].name;
      var newInstallationType = this.installationTypes[installationIndex];

      var dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
      dialogConfig.data = {
        heading: strings.aircraft_installation_type_change,
        message: strings.aircraft_installation_type_change_message.replace("{installationTypeName}", installationTypeName),
        action: strings.yes,
        cancel: strings.no
      }
      dialogConfig.disableClose = true;
      let AircraftConfirmDialog = this.matDialog.open(AircraftGeneralConfirmDialogComponent, dialogConfig);
      AircraftConfirmDialog.beforeClosed().subscribe((res: any) => { });
      AircraftConfirmDialog.afterClosed().subscribe((res: any) => {
        if (res.event == 'action') {
          this.aircraftService.setInstallationType(this.aircraft.aircraft_id, this.currentInstallationId).then(
            (res: any) => {
              this.toastService.toastSuccess("success_change_installationType", "aircraft_management");
              this.aircraftService.getAircraftConnectivityTypes(this.aircraft.aircraft_id).then(
                (res: any) => { this.connectivityTypes = res; }
              );

              this.aircraftService.getDownloadPreferences(this.aircraft.aircraft_id).then(
                (res: any) => { this.downloadPreferences = res }
              );

              this.aircraftService.getAircraftStageClient(this.aircraft.aircraft_id).then(
                (res: any) => { this.currentStageClientId = this.previousStageClientId = res;
                  this.stageClientCurrentTitle();
                }
              );
              this.mediaStorageSizeCleanCall();

              this.currentInstallationType = newInstallationType;
              return this.currentInstallationId;
            },
            (err: any) => {
              this.currentInstallationId = this.currentInstallationType.id;
              return this.currentInstallationType.id;
            }
          );
        } else {
          //this.toastService.toastSuccess("error_change_installationType", "aircraft_management");
          this.currentInstallationId = this.currentInstallationType.id;
          return this.currentInstallationType.id;
        }
      });
    } else {
      var installationIndex: number = _.findIndex(this.installationTypes,
        _.find(this.installationTypes, { id: this.currentInstallationId }));
      this.currentInstallationType = this.installationTypes[installationIndex];

      return this.currentInstallationType.id;
    }
  }

  onChangeStageClient() {
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
    dialogConfig.data = {
      heading: strings.aircraft_stage_client_change,
      message: strings.aircraft_stage_client_change_message,
      action: strings.yes,
      cancel: strings.no
    }
    dialogConfig.disableClose = true;
    let AircraftConfirmDialog = this.matDialog.open(AircraftGeneralConfirmDialogComponent, dialogConfig);
    AircraftConfirmDialog.beforeClosed().subscribe(
      (res: any) => { }
    );

    AircraftConfirmDialog.afterClosed().subscribe((res: any) => {
      if (res.event == 'action') {
        this.aircraftService.setAircraftStageClient(this.aircraft.aircraft_id, this.currentStageClientId).then(
          (res: any) => {
            this.previousStageClientId = this.currentStageClientId;
            return this.currentStageClientId;
          },
          (err: any) => {
            this.currentStageClientId = this.previousStageClientId;
            return this.currentStageClientId;
          }
        );
      } else {
        this.currentStageClientId = this.previousStageClientId;
        return this.currentStageClientId;
      }
    });
  }

  onChangeDCSLVersion() {
    if (!this.dialogData.createNew) {
      var index: number = _.findIndex(this.dcslVersions,
        _.find(this.dcslVersions, { id: this.currentDCSLVersionId }));
      let dcslName: string = this.dcslVersions[index].name;
      var newDcslVersion = this.dcslVersions[index];

      var dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
      dialogConfig.data = {
        heading: strings.aircraft_dcsl_version_change,
        message: strings.aircraft_dcsl_version_change_message.replace("{dcslVersion}", dcslName),
        action: strings.yes,
        cancel: strings.no
      }
      dialogConfig.disableClose = true;
      let AircraftConfirmDialog = this.matDialog.open(AircraftGeneralConfirmDialogComponent, dialogConfig);
      AircraftConfirmDialog.beforeClosed().subscribe((res: any) => { });
      AircraftConfirmDialog.afterClosed().subscribe((res: any) => {
        if (res.event == 'action') {
          this.aircraftService.setDCSLVersion(this.aircraft.aircraft_id, this.currentDCSLVersionId).then(
            (res: any) => {
              this.toastService.toastSuccess("success_change_dcsl_version", "aircraft_management");

              this.currentDCSLVersion = newDcslVersion;
              return this.currentDCSLVersionId;
            },
            (err: any) => {
              this.currentDCSLVersionId = this.currentDCSLVersion.id;
              return this.currentDCSLVersion.id;
            }
          );
        } else {
          this.currentDCSLVersionId = this.currentDCSLVersion.id;
          return this.currentDCSLVersion.id;
        }
      });
    } else {
      var index: number = _.findIndex(this.dcslVersions,
        _.find(this.dcslVersions, { id: this.currentDCSLVersionId }));
      this.currentDCSLVersion = this.dcslVersions[index];

      return this.currentDCSLVersion.id;
    }
  }
  ngOnInit() {
    this.isLoadingLinkButton = true;
    this.aircraftService.getManufacturers().then(
      (res: any) => { this.manufacturers = res }
    );

    if (this.dialogData.createNew) {
      this.hasAircraft = false;
      this.aircraft = new Aircraft();
      this.aircraftService.getManufacturerModels(this.dialogData.manufacturer).then(
        (res: any) => {
          if (!!res[0]) {
            var currentManufacturer = res[0].id;
            this.manufacturerModels = this.aircraftService.getManufacturerModels(currentManufacturer);
          }
        });

      if (!!this.dialogData.operator) {
        var operatorIndex: number = _.findIndex(this.operators, _.find(this.operators, {
          name: this.dialogData.operator
        }));
        this.aircraft.operator_id = this.operators[operatorIndex].name;
      }

      this.hasContentDiskSpace = true;

      this.aircraftService.getAircraftInstallationTypes().then(
        (res: any) => { this.installationTypes = res },
        (err: any) => { console.log("error", err); }
      );
    }
    else {
      this.aircraftService.getMsuConfiguration(this.dialogData.aircraft_id).then(
        (res: any) => {
          this.configuration = res;
          this.configLoad = true;
          // Call getFileInfo method to get the details of first configuration file
          // If configuration data is available for selected aircraft
          if (this.configuration) {
            this.getFileInfo(this.configuration.id).then((res: any) => {
            })
          }
        },
      );
      this.aircraftService.getAircraftDetails(this.dialogData.aircraft_id).then(
        ((res: any) => {
          this.aircraft = res;
          this.aircraft.content_disk_space = ((this.aircraft.content_disk_space) / (Math.pow(1024, 2))).toFixed(1);
          this.hasContentDiskSpace = true;
          this.currentInstallationId = this.aircraft.installation_id;
        
          if(this.aircraft.isLatestDCSL)
            this.currentDCSLVersionId=2;
          else
            this.currentDCSLVersionId=1;
          this.aircraftService.getAircraftInstallationTypes().then(
            (res: any) => {
              this.installationTypes = res;
              var installationIndex: number = _.findIndex(this.installationTypes,
                _.find(this.installationTypes, { id: this.currentInstallationId }));
              this.currentInstallationType = this.installationTypes[installationIndex];
            },
            (err: any) => { console.log("error", err); }
          );
        })
      );

      this.aircraftService.getManufacturerModels(this.dialogData.manufacturer).then(
        (res: any) => { this.manufacturerModels = res }
      );

      this.aircraftService.getAircraftConnectivityTypes(this.dialogData.aircraft_id).then(
        (res: any) => {
          this.connectivityTypes = res;
        }
      );

      this.operatorService.getAllOperators().then(
        (res: any) => { this.operators = res }
      );

      this.aircraftService.getDownloadPreferences(this.dialogData.aircraft_id).then(
        (res: any) => { this.downloadPreferences = res }
      );

      this.subscriptionService.getCurrentSubscriptionPlan(this.dialogData.aircraft_id).then(
        (res: any) => { this.selectedSubscriptionPlan = res }
      );

      this.aircraftService.getMsuConfigurations(this.originalAircraft.aircraft_id).then(
        (res: any) => { this.configHistoryDetails = res }
      );

      this.aircraftService.getStageClients().then(
        (res: any) => { this.stageClients = res }
      );

      this.aircraftService.getAircraftStageClient(this.dialogData.aircraft_id).then(
        (res: any) => { this.currentStageClientId = this.previousStageClientId = res;
          this.stageClientCurrentTitle(); },
        (err: any) => { console.log("Error: " + err) }
      );
    }

    this.aircraftService.getDCSLVersions().then(
      (res: any) => { this.dcslVersions = res; 
        this.dcslVersions = res;
              var index: number = _.findIndex(this.dcslVersions,
                _.find(this.dcslVersions, { id: this.currentDCSLVersionId }));
              this.currentDCSLVersion = this.dcslVersions[index];
       },
      (err: any) => { console.log("error", err); }
    );
  }

  openTab(tabName: string) {
    this.currentTab = tabName;
  }

  changeOperator() {
    this.operator_active = true;
  }

  cancelOperatorChange() {
    this.operator_active = false;
  }

  getManufacturerModels() {
    var url: string = settings.configuration.env.apiUrl + 'fleet/manufacturer/' + this.currentManufacturer + '/models';
    this.currentModel = '';
    return this.http
      .get(url)
      .subscribe((res: string) => { this.manufacturerModels = res });
  }

  // Function to get the information of configuration file and parse the data to json to display in UI
  getFileInfo(confid) {
    this.downloadConfigId = confid;
    var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + this.originalAircraft.aircraft_id + '/msuconfigurationslist/' + confid;
    var localDetails;

    var httpParams = new HttpParams()
      .append('cache', 'false');

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.http
      .get(url, { headers: httpHeaders, params: httpParams, responseType: "text", observe: 'response' })
      .toPromise().then((res: any) => {
        localDetails = res.body;
        try {
          // Parse data into the JSON format
          localDetails = localDetails.replace(/\s/g, '');
          localDetails = JSON.parse(localDetails);
          if (typeof (localDetails) !== 'object')
            localDetails = JSON.parse(localDetails);
          this.configJSONParsed = true;
        }
        catch (error) {
          this.configJSONParsed = false;
          // Error in JSON parsing
          // store raw text as it is
        }
        this.fileDetails = localDetails;
        return res;
      });
  }

  request() {
    var confid = this.downloadConfigId;
    var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + this.originalAircraft.aircraft_id + '/msuconfigurationslist/' + confid;

    var httpParams = new HttpParams()
      .append('cache', 'false');

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.http
      .get(url, { headers: httpHeaders, params: httpParams, responseType: "arraybuffer", observe: 'response' })
      .subscribe(
        (res: HttpResponse<any>) => {

          var octetStreamMime = 'application/octet-stream';
          var success = false;

          // Get the filename from the x-filename header or default to "download.bin"
          var filename = res.headers.get('filename') || 'config.json';

          // Determine the content type from the header or default to "application/octet-stream"
          var contentType = res.headers.get('content-type') || octetStreamMime;

          try {
            var blob = new Blob([res.body], { type: contentType });
            if (window.navigator['msSaveBlob'])
              window.navigator.msSaveBlob(blob, filename);
            else {
              // Try using other saveBlob implementations, if available
              var saveBlob = window.navigator['webkitSaveBlob'] || window.navigator['mozSaveBlob'] || window.navigator['saveBlob'];
              if (saveBlob === undefined) throw "Not supported";
              saveBlob(blob, filename);
            }
            success = true;
          } catch (ex) {
            ;
            console.log(ex);
          }

          if (!success) {
            // Get the blob url creator
            var urlCreator = window['URL'] || window['webkitURL'] || window['mozURL'] || window['msURL'];
            if (urlCreator) {
              // Try to use a download link
              var link = document.createElement('a');
              if ('download' in link) {
                // Try to simulate a click
                try {
                  // Prepare a blob URL
                  var blob = new Blob([res.body], {
                    type: contentType
                  });
                  var url = urlCreator.createObjectURL(blob);
                  link.setAttribute('href', url);

                  // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                  link.setAttribute("download", filename);

                  // Simulate clicking the download link
                  var event = document.createEvent('MouseEvents');
                  event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                  link.dispatchEvent(event);
                  success = true;

                } catch (ex) {
                  console.log(ex);
                }
              }

              if (!success) {
                // Fallback to window.location method
                try {
                  // Prepare a blob URL
                  // Use application/octet-stream when using window.location to force download
                  var blob = new Blob([res.body], {
                    type: octetStreamMime
                  });
                  var url = urlCreator.createObjectURL(blob);
                  window.location = url;
                  success = true;
                } catch (ex) {
                  console.log(ex);
                }
              }
            }
          }
        },
        (err: any) => {
          console.log(err);
        }
      )
  }

  deselectService(id: string, tail: string, index: number) {
    var url: string = settings.configuration.env.apiUrl + 'subscriptions/services/delete/subscription/' + id + '/fleet/' + tail;
    return this.http
      .post(url, null, { observe: "response" })
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.subscriptionPlanServices.splice(index, 1);
            this.toastService.toastSuccess("success_delete_subscription", "aircraft_management");
            return this.subscriptionService.getCurrentSubscriptionPlanDetails(tail).then(
              (res: any) => { this.subscriptionPlanServices = res });
          } else {
            this.toastService.toastError("error_delete_subscription", "aircraft_management");
          }
        },
        (err: any) => { this.toastService.toastError("error_delete_subscription", "aircraft_management") }
      );
  }

  selectConnectivityType(type: any) {
    var typeStatus: number = type.is_selected;
    var typeId: string = type.id;
    var btypeStatus: boolean = false;

    switch (typeStatus) {
      case 4:
        btypeStatus = false;
        break;
      case 0:
        btypeStatus = true;
        break;
    }

    return this.aircraftService.setConnectivityType(this.originalAircraft.aircraft_id, typeId, btypeStatus).then(
      (res: any) => {
        if (res.status === 200) {
          var newStatus = res.body.is_selected;
          this.toastService.toastSuccess("success_connection_type_updated", "aircraft_management");
          _.find(this.connectivityTypes, { id: typeId }).is_selected = newStatus;
          this.downloadPreferencesCleanCall();
        } else {
          this.toastService.toastError("error_connection_type_not_updated", "aircraft_management");
        }
      },
      (err: any) => { this.toastService.toastError("error_connection_type_not_updated", "aircraft_management") }
    );
  }

  createAircraft() {
    //this.currentStageClientId = this.currentInstallationType.StageClientId;
    var url: string = settings.configuration.env.apiUrl + 'fleet/create/aircraft';
    return this.http
      .post(url, {
        manufacturer: this.currentManufacturer,
        model: this.currentModel,
        aircraft_id: this.aircraft.aircraft_id,
        serial_number: this.aircraft.serial_number,
        content_disk_space: Math.ceil((this.config.AIRCRAFT_DETAILS.MEDIA_DRIVE_SIZE) * (Math.pow(1024, 2))).toString(),
        operator_id: this.aircraft.operator_id,
        installation_id: this.currentInstallationId,
        dcsl_version:this.currentDCSLVersion['id']
      }, { observe: 'response' })
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.toastService.toastSuccess("success_aircraft_created", "aircraft_management");
            this.originalAircraft = angular.extend({}, this.aircraft);
            this.originalAircraftId = this.originalAircraft.aircraft_id;
            this.connectivityTypesCleanCall();
            this.downloadPreferencesCleanCall();
            this.mediaStorageSizeCleanCall();
            this.aircraftService.getStageClients().then(
              (res: any) => {
                this.stageClients = res;
                this.aircraftService.getAircraftStageClient(this.originalAircraftId).then(
                  (res: any) => { this.currentStageClientId = this.previousStageClientId = res;
                    this.stageClientCurrentTitle(); },
                  (err: any) => { console.log("Error: " + err) }
                )
              }
            );

            this.hasAircraft = true;
            this.dialogData.createNew = false;
            if (this.hasNewImage) {
              this.postImage(this.currentManufacturer);
            }

            this.mediadriveService.getMediaDrive();
            // To fix refresh issue without closing dialog
            this.subscriptionService.getCurrentSubscriptionPlan(this.dialogData.aircraft_id).then(
              (res: any) => {
                this.selectedSubscriptionPlan = res;
               }
            );
          } else {
            this.toastService.toastError("error_create_aircraft", "aircraft_management");
          }
        },
        (err: any) => {
          if (err.status == 409) {
            this.toastService.toastError("error_aircraft_exists", "aircraft_management");
          } else {
            this.toastService.toastError("error_create_aircraft", "aircraft_management");
          }
        }
      )
  }

  saveChanges() {
    var id: string = this.aircraft.aircraft_id;
    var url: string = settings.configuration.env.apiUrl + 'fleet/edit/aircraft/' + id;

    var params = {
      manufacturer: this.currentManufacturer,
      model: this.currentModel,
      aircraft_id: this.aircraft.aircraft_id,
      serial_number: this.aircraft.serial_number,
      content_disk_space: Math.ceil((this.aircraft.content_disk_space) * (Math.pow(1024, 2))).toString(),
      operator_id: this.aircraft.operator_id,
      installation_id: this.currentInstallationId
    }

    var returnValue = this.http
      .post(url, params, { observe: "response" })
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            if (this.hasNewImage) {
                this.postImage(this.currentManufacturer);
            }
            if(this.imageDeleted){
              this.deleteAircraftImage();
            }
            this.toastService.toastSuccess("success_aircraft_info_updated", "aircraft_management");
          } else {
            this.toastService.toastError("error_aircraft_info_update", "aircraft_management");
          }

          this.mediadriveService.getMediaDrive();
        },
        (err: any) => { this.toastService.toastError("error_aircraft_info_update", "aircraft_management"); }
      );

    return returnValue;
  }


  loadImage(event) {
    this.showImageOptions = false;
    var newImage = new Image();
    var reader = new FileReader();
    reader.onload = () => {

      let output: HTMLElement = document.getElementById('output');
      newImage.setAttribute('src', reader.result.toString());

      setTimeout(() => {
        if (newImage.naturalHeight < 215 && newImage.naturalWidth < 260 ||
          newImage.naturalHeight > 215 && newImage.naturalWidth < 260 ||
          newImage.naturalHeight < 215 && newImage.naturalWidth > 260
        ) {
          event.target.files[0] = null;
          this.hasNewImage = false;
          output.setAttribute('src', this.placeholder);
          this.toastService.toastError("error_min_img_size", "aircraft_management");
        } else {
          output.setAttribute('src', reader.result.toString());
          this.personalFile = event.target.files[0];
        }
      }, 300);

    };

    if (event.target.files[0].type == 'image/png' ||
      event.target.files[0].type == 'image/jpg' ||
      event.target.files[0].type == 'image/jpeg') {
      this.hasNewImage = true;
      reader.readAsDataURL(event.target.files[0]);
    } else {
      event.target.files[0] = null;
      this.hasNewImage = false;
      this.toastService.toastError("error_only_images_allowed", "aircraft_management");
      return;
    }
  };

  clearAircraftImage() {
    let output: HTMLElement = document.getElementById('output');
    this.showImageOptions = false;
    this.hasNewImage = false;
    this.imageDeleted = true;
    output.setAttribute('src', this.placeholder);
  }

  postImage(manufacturer: string) {
    var file: any = this.personalFile;
    var fileName: string = file.name;
    var fd = new FormData();
    fd.append('file', file);
    var url: string = settings.configuration.env.apiUrl + 'fleet/postimage/aircraft/' + this.originalAircraft.aircraft_id;

    var httpHeaders = new HttpHeaders({
      //'Content-Type': undefined,
      'filename': fileName
    });

    var options = {
      //transformRequest: angular.identity,
      headers: httpHeaders,
      observe: 'response' as 'body'
    };

    return this.http
      .post(url, fd, options)
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.showImageOptions = false;
            this.timeStamp = '?=' + new Date().getTime();
            this.toastService.toastSuccess("success_aircraft_image_uploaded", "aircraft_management");
          } else {
            this.timeStamp = '?=' + new Date().getTime();
            this.toastService.toastError("error_upload_image", "aircraft_management");
          }
        },
        (err: any) => {
          this.toastService.toastError("error_upload_image", "aircraft_management");
        }
      );
  }

  deleteAircraftImage() {
    this.showImageOptions = false;
    var url: string = settings.configuration.env.apiUrl + 'fleet/deleteimage/aircraft/' + this.originalAircraft.aircraft_id;
    return this.http
      .post(url, null, { observe: 'response' })
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.timeStamp = '?=' + new Date().getTime();
            this.showImageOptions = false;
            this.toastService.toastSuccess("success_aircraft_image_deleted", "aircraft_management");
          } else {
            this.toastService.toastError("error_delete_image", "aircraft_management");
          }
        },
        (err: any) => { this.toastService.toastError("error_delete_image", "aircraft_management"); }
      );
  }

  selectDownloadPreferenceType(channel: string, preference: any, title: string) {
    var selectedStatus: number = preference.is_selected;
    var connection_type: string = preference.title;

    if (!this.blockUI) {
      this.blockUI = true;
      var dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: title,
        connection_type: connection_type,
        selectedStatus: selectedStatus
      };

      dialogConfig.panelClass = ['matdialog-custom-dialog', 'sync-preference-change'];
      let dialogRef = this.syncPreferenceChangeDialog.open(SyncPreferenceChangeDialogComponent, dialogConfig);

      dialogRef.afterClosed()
        .subscribe(
          (result: any) => {
            if (result.event == 'close') {
              let selected: string = '';
              if (selectedStatus == 0) {
                preference.is_selected = 4;
                selected = "true";
              } else if (selectedStatus === 4) {
                preference.is_selected = 0;
                selected = "false";
              }

              this.aircraftService.setDownloadPreference(this.originalAircraftId, selected, channel, preference.id, result.data).then(
                (res: any) => {
                  if (res.status === 200) {
                    if (res.body.is_selected === preference.is_selected) {
                      this.toastService.toastSuccess("success_aircraft_download_method_updated", "aircraft_management");
                    }
                  } else {
                    this.toastService.toastError("error_aircraft_download_method_update", "aircraft_management");
                  }
                },
                (err: any) => {
                  this.toastService.toastError("error_aircraft_download_method_update", "aircraft_management");
                  preference.is_selected = selectedStatus;
                },
              );
            }

            this.blockUI = false;
          }
        );
    }
  }

  downloadPreferencesCleanCall() {
    return this.aircraftService.getDownloadPreferences(this.originalAircraftId).then(
      (res: any) => { this.downloadPreferences = res });
  }

  connectivityTypesCleanCall() {
    return this.aircraftService.getAircraftConnectivityTypes(this.originalAircraftId).then(
      (res: any) => { this.connectivityTypes = res });
  }

  mediaStorageSizeCleanCall() {
    return this.aircraftService.getAircraftDetails(this.originalAircraftId).then(
      (res: any) => {
        this.aircraft = res;
        this.aircraft.content_disk_space = ((this.aircraft.content_disk_space) / (Math.pow(1024, 2))).toFixed(1);
      }
    )
  }

  stageClientCurrentTitle(){
    console.log("inside new logic - StageClient Current Title");
    for(var i=0; i<this.stageClients.length; i++){
      var value = this.stageClients[i];
      if(value.id === this.currentStageClientId){
        console.log("inside new logic for Stage CLient Title...", value.title);
        console.log("inside new logic for Stage CLient Title id...", value.id);

        this.currentStageClientTitle = value.title;
        break;
      }
    }
  }

  changeAircraftPassword(password: string, passwordConfirm: string) {
    var email = (<HTMLInputElement>document.getElementById("emails")).value;
    // var newPassword = this.aircraftPassword.controls.newPassword.value;
    // var confirmPassword = this.aircraftPassword.controls.confirmPassword.value;

    if (password === passwordConfirm) {
      this.aircraftService.changeAircraftPassword(this.originalAircraftId, password, passwordConfirm, email).then(
        (res: any) => {
          if (res.status == 200) {
            // this.aircraftPassword.get("newPassword").reset('');
            // this.aircraftPassword.get("confirmPassword").reset('');
            this.newPassword = '';
            this.confirmPassword = '';
            this.aircraftPassword.setValue({
              newPassword: '',
              confirmPassword: '',
              emails: ''
            });
            this.aircraftPassword.markAsPristine();
            this.toastService.toastSuccess("success_aircraft_password_changed", "aircraft_management");
          } else {
            this.toastService.toastError("error_aircraft_password_change", "aircraft_management");
          }
        },
        (err: any) => {
          if (err.modelState == undefined) {
            this.toastService.toastError(err.message, "aircraft_management");
          } else {
            for (var key in err.modelState) {
              this.toastService.toastError(err.modelState[key][0], "aircraft_management");
            }
          }
        }
      );
    } else {
      this.toastService.toastError("error_aircraft_password_match", "aircraft_management");
    }
  }

  showSubscriptionPlans() {
    this.isLoading = true;
    return this.getSubscriptionPlans().then(() => { });
  }

  getSubscriptionPlans() {
    return this.subscriptionService.getSubscriptionPlans().then(
      (res: any) => {
        this.subscriptionPlans = res;
        setTimeout(() => { this.isLoading = false }, 1000);
      });
  }

  // selectSubscriptionPlan(subscriptionDetails: any) {
  //   this.isLoading = true;
  //   var subscriptionPlanId = subscriptionDetails.name;
  //   //TODO: Display Dialog - Contains - 3 column Table and two Buttons
  //   // Fetch List of Current Services List
  //   // Fetch Newly Selected Subscription Default Service
  //   // If Movie (Silver)

  //   // On Confirm - Select Unselect Services and Display Loader with Text
  //   return this.subscriptionPlanServices
  //   return this.subscriptionService.selectSubscriptionPlan(this.originalAircraftId, subscriptionPlanId).then(
  //     (res: any) => {
  //       if (res.status == 200) {
  //         this.toastService.toastSuccess("success_aircraft_plan_selected", "aircraft_management");
  //         return this.getSelectedPlan().then(
  //           (res: any) => {
  //             this.selectedSubscriptionPlan = res;
  //           });
  //       } else {
  //         this.isLoading = false;
  //         this.toastService.toastError("error_aircraft_plan_select", "aircraft_management");
  //       }
  //     },
  //     (err: any) => {
  //       this.isLoading = false;
  //       this.toastService.toastError("error_aircraft_plan_select", "aircraft_management");
  //     }
  //   );
  // }

  selectSubscriptionPlan(subscriptionDetails: any) {
    if (!this.selectedSubscriptionPlan) {
      this.isLoading = true;
      var subscriptionPlanId = subscriptionDetails.name;
      return this.subscriptionService.selectSubscriptionPlan(this.originalAircraftId, subscriptionPlanId).then(
        (res: any) => {
          if (res.status == 200) {
            this.toastService.toastSuccess("success_aircraft_plan_selected", "aircraft_management");
            return this.getSelectedPlan().then(
              (res: any) => {
                this.selectedSubscriptionPlan = res;
                this.coreservice.selectPlan = false;
                this.coreservice.changePlan = false;
                this.isLoading = false;
              });
          } else {
            this.isLoading = false;
            this.toastService.toastError("error_aircraft_plan_select", "aircraft_management");
          }
        },
        (err: any) => {
          this.isLoading = false;
          this.toastService.toastError("error_aircraft_plan_select", "aircraft_management");
        }
      );
    } else {
      if (!this.blockUI) {
        this.blockUI = true;
        var dialogConfig = new MatDialogConfig();
        var subscriptionPlanId = subscriptionDetails.name;
        dialogConfig.panelClass = ['matdialog-custom-dialog', 'edit-aircraft-width', 'dialog'];
        dialogConfig.data = {
          heading: strings.limited_service_change_title,
          message: strings.limited_service_change_description,
          action: strings.make_changes,
          cancel: strings.cancel,
          selectedSubscriptionPlan: this.selectedSubscriptionPlan,
          planId: subscriptionPlanId,
          originalAircraftId: this.originalAircraftId,
          subscriptionDetails: subscriptionDetails,
        }
        dialogConfig.disableClose = true;
        let subscriptionPlanChangeDialogComponent = this.matDialog.open(SubscriptionPlanChangeDialogComponent, dialogConfig);
        subscriptionPlanChangeDialogComponent.beforeClosed().subscribe(
          (res: any) => {
           }
        );

        subscriptionPlanChangeDialogComponent.afterClosed().subscribe((res: any) => {
          this.blockUI = false;
          return this.getSelectedPlan().then(
            (res: any) => {
              this.selectedSubscriptionPlan = res;
            });
        });
      }
    }
  }

  getSelectedPlan() {
    return this.subscriptionService.getCurrentSubscriptionPlan(this.originalAircraftId);
  }

  editSubscriptionPlan() {
    this.isLoading = true;
    return this.getSelectedSubscriptionPlanDetails().then(
      (res: any) => {
        setTimeout(() => { this.isLoading = false }, 1000);
        //this.setLimitedSubscription();
      });

  }

  getSelectedSubscriptionPlanDetails() {
    return this.subscriptionService.getCurrentSubscriptionPlanDetails(this.originalAircraftId).then(
      (res: any) => {
        this.subscriptionPlanServices = res;

        for (var i = 0; i < this.subscriptionPlanServices.length; i++) {
          if (this.subscriptionPlanServices[i].service_name == 'MOVIES') {
            // Setting Current Selected Limited Service
            if (this.subscriptionPlanServices[i].limited_service_name != null) {
              this.currentLimitedServiceMovieId = this.subscriptionPlanServices[i].limited_service_name;
              this.previousLimitedServiceMovieId = this.subscriptionPlanServices[i].limited_service_name;
            } else {
              // set 'All' when limited service is null
              this.currentLimitedServiceMovieId = 'All';
              this.previousLimitedServiceMovieId = 'All';
            }
          }
          else if (this.subscriptionPlanServices[i].service_name == 'TVSHOWS') {
            if (this.subscriptionPlanServices[i].limited_service_name != null) {
              this.currentLimitedServiceTVShowId = this.subscriptionPlanServices[i].limited_service_name;
              this.previousLimitedServiceTVShowId = this.subscriptionPlanServices[i].limited_service_name;
            } else {
              // set 'All' when limited service is null
              this.currentLimitedServiceTVShowId = 'All';
              this.previousLimitedServiceTVShowId = 'All';
            }

            // Calling Limited Service list API to fill Dropdown
            var serviceName = this.subscriptionPlanServices[i].service_name;
            return this.subscriptionService.getLimitedServicesDetails(this.originalAircraftId, serviceName).then(
              (res: any) => {
                if (res != null) {
                  this.limitedServices = res;
                }
              });
          }

        }
      });
  }

  onChangeLimitedService(serviceName) {
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
    dialogConfig.data = {
      heading: strings.limited_service_change_title,
      message: strings.limited_service_change_description,
      action: strings.change,
      cancel: strings.cancel
    }
    dialogConfig.disableClose = true;
    let LimitedServicesDialog = this.matDialog.open(LimitedServicesDialogComponent, dialogConfig);
    LimitedServicesDialog.beforeClosed().subscribe(
      (res: any) => { }
    );

    LimitedServicesDialog.afterClosed().subscribe((res: any) => {
      if (res.event == 'action') {
        this.serviceId = serviceName;
        var currentLimitedServiceId = "";
        if (this.serviceId == "MOVIES") {
          currentLimitedServiceId = this.currentLimitedServiceMovieId;
        }
        else if (this.serviceId == "TVSHOWS") {
          currentLimitedServiceId = this.currentLimitedServiceTVShowId;
        }

        this.subscriptionService.setLimitedService(this.aircraft.aircraft_id, currentLimitedServiceId, this.serviceId).then(
          (res: any) => {
            this.toastService.toastSuccess("success_aircraft_subscription_plan_update", "aircraft_subscription_management");
            return currentLimitedServiceId;
          },
          (err: any) => {
            if (serviceName == "MOVIES") {
              this.currentLimitedServiceMovieId = this.previousLimitedServiceMovieId;
              return this.currentLimitedServiceMovieId;
            }
            else if (serviceName == "TVSHOWS") {
              this.currentLimitedServiceTVShowId = this.previousLimitedServiceTVShowId;
              return this.currentLimitedServiceTVShowId;
            }
          }
        );
      } else {
        if (serviceName == "MOVIES") {
          this.currentLimitedServiceMovieId = this.previousLimitedServiceMovieId;
          return this.currentLimitedServiceMovieId;
        }
        else if (serviceName == "TVSHOWS") {
          this.currentLimitedServiceTVShowId = this.previousLimitedServiceTVShowId;
          return this.currentLimitedServiceTVShowId;
        }
      }
    });
  }

  // setLimitedSubscription()
  // {
  //     //this.currentLimitedServiceId = 'PREVIEW';
  //     this.subscriptionService.setLimitedService(this.aircraft.aircraft_id, this.currentLimitedServiceId).then(
  //         (res: any) => {
  //           this.previousLimitedServiceId = this.currentLimitedServiceId;
  //           return this.currentLimitedServiceId;
  //         },
  //         (err: any) => {
  //           this.currentLimitedServiceId = this.previousLimitedServiceId;
  //           return this.currentLimitedServiceId;
  //         }
  //       );
  // }

  getConfigHistory() {
    return this.aircraftService.getMsuConfigurations(this.originalAircraft.aircraft_id).then(
      (res: any) => { this.configHistoryDetails = res });
  }

  subscribeService(service: any, index: number) {
    this.serviceIndex = index;
    var isSelected: number = service.is_selected;
    var status: boolean = false;

    switch (isSelected) {
      case 4:
        status = false;
        break;
      case 0:
        status = true;
        break;
    }

    if (status === false) {
      var showCarryOnWarnig = false;
      if (service.service_name == "OPERATOR_CONTENT") {
        for (var i = 0; i < this.subscriptionPlanServices.length; i++) {
          if (this.subscriptionPlanServices[i].service_name == "CARRYON") {
            if (this.subscriptionPlanServices[i].is_selected == 4) {
              showCarryOnWarnig = true;
            }
          }
        }
      }

      var dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'aircraft-service-disable', 'dialog'];
      dialogConfig.data = {
        heading: strings.aircraft_disable_service,
        message: strings.aircraft_disable_service_message.replace("{XXX}", service.service_title),
        messagewarning: strings.aircraft_disable_warning_service_message.replace("{XXX}", service.service_title),
        action: strings.disable,
        cancel: strings.cancel,
        showCarryOnWarnig: showCarryOnWarnig
      }

      dialogConfig.disableClose = true;
      this.loading[this.serviceIndex] = true;

      let AircraftConfirmDialog = this.matDialog.open(AircraftGeneralConfirmDialogComponent, dialogConfig);
      AircraftConfirmDialog.beforeClosed().subscribe((res: any) => { });
      AircraftConfirmDialog.afterClosed().subscribe(
        (res: any) => {
          if (res.event === 'action') {
            this.subscriptionService.subscribeService(service, this.originalAircraft.aircraft_id, status).then(
              (res: any) => {
                if (res.status === 200) {
                  let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                  this.subscriptionPlanServices[getIndex] = res.body

                  // Special condition for Carry on content. If Operator  on content service un subscribs and if carry on content service is enabled then carry service should disabled default.
                  var updateCarryOn = false;
                  var carryOnIndex = 0;
                  for (var i = 0; i < this.subscriptionPlanServices.length; i++) {
                    if (this.subscriptionPlanServices[i].service_name == "CARRYON") {
                      if (this.subscriptionPlanServices[i].is_selected == 4) {
                        if (showCarryOnWarnig) {
                          this.subscriptionPlanServices[i].is_selected = 0;
                          updateCarryOn = true;
                          carryOnIndex = i;
                        }
                      }
                    }
                  }

                  if (updateCarryOn) {
                    return this.subscriptionService.subscribeService(this.subscriptionPlanServices[carryOnIndex], this.originalAircraft.aircraft_id, status).then(
                      (res: any) => {
                        if (res.status === 200) {
                          let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                          this.loading[getIndex] = false;
                          this.subscriptionPlanServices[getIndex] = res.body;
                          this.toastService.toastSuccess("success_aircraft_service_updated", "aircraft_subscription_management");
                          this.loading = [];
                        } else {
                          let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                          this.loading[getIndex] = false;
                          this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                        }
                        this.serviceIndex = null;
                      },
                      (err: any) => {
                        let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === service.service_name);
                        this.loading[getIndex] = false;
                        this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                      }
                    );
                  }
                  else {
                    let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === service.service_name);
                    this.loading[getIndex] = false;
                    this.toastService.toastSuccess("success_aircraft_service_updated", "aircraft_subscription_management");
                    getIndex = null;
                  }
                } else {
                  let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                  this.loading[getIndex] = false;
                  this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                }
              },
              (err: any) => {
                let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === service.service_name);
                this.loading[getIndex] = false;
                this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
              }
            );
          } else if (res.event === 'cancel') {
            let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === service.service_name);
            this.loading[getIndex] = false;
          }
        }
      );
    } else if (status === true && service.service_name == "CARRYON") {
      var dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
      dialogConfig.data = {
        heading: strings.aircraft_enable_service,
        message: strings.aircraft_enable_service_message.replace("{XXX}", service.service_title),
        action: strings.enable,
        cancel: strings.cancel
      }

      dialogConfig.disableClose = true;
      this.loading[this.serviceIndex] = true

      let AircraftConfirmDialog = this.matDialog.open(AircraftServiceEnableDialogComponent, dialogConfig);
      AircraftConfirmDialog.beforeClosed().subscribe((res: any) => { });
      AircraftConfirmDialog.afterClosed().subscribe(
        (res: any) => {
          if (res.event === 'action') {
            this.loading[this.serviceIndex] = true

            // Special condition for Carry on content. If Carry on content service subscribs then operator subscription should enabled default.
            // So below code is to check if operator service is not enalble then dynamically enable operator services.
            var updateOperator = false;
            var OperatorIndex = 0;
            for (var i = 0; i < this.subscriptionPlanServices.length; i++) {
              if (this.subscriptionPlanServices[i].service_name == 'OPERATOR_CONTENT') {
                if (this.subscriptionPlanServices[i].is_selected == 0) {
                  this.subscriptionPlanServices[i].is_selected = 4;
                  updateOperator = true;
                  OperatorIndex = i;
                }
              }
            }

            if (updateOperator) {
              this.subscriptionService.subscribeService(this.subscriptionPlanServices[OperatorIndex], this.originalAircraft.aircraft_id, status).then(
                (res: any) => {
                  if (res.status === 200) {
                    let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                    this.subscriptionPlanServices[getIndex] = res.body;
                    return this.subscriptionService.subscribeService(service, this.originalAircraft.aircraft_id, status).then(
                      (res: any) => {
                        if (res.status === 200) {
                          let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                          this.loading[getIndex] = false;
                          this.subscriptionPlanServices[getIndex] = res.body;
                          this.toastService.toastSuccess("success_aircraft_service_updated", "aircraft_subscription_management");
                          getIndex = null;
                        } else {
                          let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                          this.loading[getIndex] = false;
                          this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                        }
                      },
                      (err: any) => {
                        let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === service.service_name);
                        this.loading[getIndex] = false;
                        this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                      }
                    );
                  } else {
                    let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                    this.loading[getIndex] = false;
                    this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                  }
                },
                (err: any) => {
                  this.loading = [];
                  this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                }
              );
            }
            else {
              return this.subscriptionService.subscribeService(service, this.originalAircraft.aircraft_id, status).then(
                (res: any) => {
                  if (res.status === 200) {
                    let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                    this.loading[getIndex] = false;
                    this.subscriptionPlanServices[getIndex] = res.body;
                    this.toastService.toastSuccess("success_aircraft_service_updated", "aircraft_subscription_management");
                    getIndex = null;
                  } else {
                    let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
                    this.loading[getIndex] = false;
                    this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                  }
                },
                (err: any) => {
                  this.loading = [];
                  this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
                }
              );
            }
          } else if (res.event === 'cancel') {
            let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === service.service_name);
            this.loading[getIndex] = false;
          }
        }
      );
    } else {
      this.loading[this.serviceIndex] = true;

      return this.subscriptionService.subscribeService(service, this.originalAircraft.aircraft_id, status).then(
        (res: any) => {
          if (res.status === 200) {
            let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
            this.loading[getIndex] = false;
            this.subscriptionPlanServices[getIndex] = res.body;
            this.toastService.toastSuccess("success_aircraft_service_updated", "aircraft_subscription_management");
            getIndex = null;
          } else {
            let getIndex = this.subscriptionPlanServices.findIndex(x => x.service_name === res.body.service_name);
            this.loading[getIndex] = false;
            this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
          }
        },
        (err: any) => {
          this.loading = [];
          this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
        }
      );
    }
  }
}

class Aircraft {
  public aircraft_id: string = '';
  public manufacturer: string = '';
  public model: string = '';
  public serial_number: string = '';
  public content_disk_space: number = 0;
  public operator_id: string = '';
  public size: string = '';
  public connection_type: string = '';
}
