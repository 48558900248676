import { Component, OnInit, DoCheck, KeyValueDiffers } from '@angular/core';
declare var angular: angular.IAngularStatic;
import settings from '../../../config.json';
import { Config }  from '../core/config';
import { DataService } from '../core/services/data.service';
import { ChannelService } from '../core/services/channel.service';
import { SortingService } from '../core/services/sorting.service';
import { PlaylistService } from '../core/services/playlist.service';
import { Router, ActivatedRoute, UrlSegment, NavigationEnd } from '@angular/router';
import { MediadriveService } from '../core/services/mediadrive.service';
import { HttpClient } from '@angular/common/http';
import {MatDialog, MatDialogConfig } from "@angular/material";
import { MovieDialog } from '../video/movie-dialog.component';
import { SelectItemsService } from '../core/services/select-items.service';
import { CoreService } from '../core/services/core.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { AuthorizedService } from '../core/services/authorized.service';
import { Location } from "@angular/common";
import { ToastService } from '../core/services/toast.service';
import { AircraftService } from '../core/services/aircraft.service';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'moviecontent',
  templateUrl: './moviecontent.component.html',
  styleUrls: ['./video.less', '../layout/layout.less']
})
export class MoviecontentComponent implements OnInit, DoCheck {

  public coverImage;
  public image;
  public imageSml;
  public imageCategory;
  public imageChannel;
  public checkLoading: boolean;
  public getMore: boolean;
  public catalog: any = [];
  public filtering;
  public genreFilter;
  public filterFlag: boolean;
  public langFlag: boolean;
  public sortFlag: boolean;
  public channelFlag: boolean;
  public contentFlag: boolean;
  private filter;
  public sorting;
  public sortBy;
  private sort;
  public languages;
  public languageFilter;
  private language;
  public contentItems;
  public channelItems;
  public dateTime = settings.configuration.datetime_formats;
  public isPlaylist;
  public message;
  public isLoading;
  public differ;
  public dataLimit;
  public content_search
  private blockUI = false;
  public aircraft_id: any = [];
  public isUpdateAvailable: boolean = false;
  public audienceList;
  public selectedAudience;
  public audience;

  constructor(private config: Config,
              private dataService: DataService,
              private channelService: ChannelService,
              private sortingService: SortingService,
              private playlistService: PlaylistService,
              private route: ActivatedRoute,
              private mediadriveService: MediadriveService,
              private http: HttpClient,
              private dialog: MatDialog,
              private selectItems: SelectItemsService,
              public coreservice: CoreService,
              public authorizedService: AuthorizedService,
              private toastService: ToastService,
              private router: Router,
              private location: Location,
              private aircraftService: AircraftService,
              protected toastrService: ToastrService,
              private differs: KeyValueDiffers) {

    this.differ = this.differs.find({}).create();
    this.getMovieData();

    if(this.coreservice.aircraft)
      this.aircraft_id = this.coreservice.aircraft.aircraft_id;

    this.coverImage = settings.configuration.env.apiUrl;
    this.image = settings.configuration.env.apiUrl + 'images/movies/' + this.config.IMAGE_FORMATS.MOVIES_GRID + '/';
    this.imageSml = settings.configuration.env.apiUrl + 'images/movies/' + this.config.IMAGE_FORMATS.MOVIES_LIST + '/';
    this.imageCategory = settings.configuration.env.apiUrl + 'images/categories/' + this.config.IMAGE_FORMATS.DEFAULT_SQUARE + '/';
    this.imageChannel = settings.configuration.env.apiUrl + 'images/channels/' + this.config.IMAGE_FORMATS.CHANNEL_BANNER + '/';
    this.checkLoading = true;
    this.getMore = true;
    this.catalog = null;
    this.filterFlag = false;
    this.langFlag = false;
    this.sortFlag = false;
    this.channelFlag = false;
    this.contentFlag = false;
    this.dataLimit = 30;
    this.coreservice.spinner = true;
    //console.log("window.location.pathname", window.location.pathname);
  }

  ngOnInit() {

  }

  ngDoCheck(){
    //console.log('item changed');
    if (!!this.coreservice.aircraft) {
      const change = this.differ.diff(this.coreservice);
      if (change) {
        change.forEachChangedItem(item => {
          if (item.currentValue != item.previousValue && item.key == 'aircraft') {
            //this.catalog = [];
            //console.log("aircraft change", this.coreservice.hideSpinner);
            this.cleanCall();
            console.log("aircraft CHanged... MsuDetails Called...");

          }
        })
      }
    }
  }

  trackByFn(index, video){
     return video.movie_id;
  }
  async getMovieData() {
    //console.log("window.location.pathname", window.location.pathname);
    var path = window.location.pathname;

    //console.log("substr",path.substr(path.lastIndexOf('/') + 1, path.length));

    //console.log("current route", this.route.url.subscribe((url: urlSegment[])=> console.log(url[0].path)))
    // if (window.location.pathname == '/movies') {
    //    this.channelService.getChannels('movie').then((res: any) => {
    //       this.channelItems = res;
    //    });
    //    this.sortingService.getFiltering('movies').then((res: any) => {
    //       this.filtering = res;
    //    });
    //    this.sortingService.getSorting('movies').then((res: any) => {
    //       this.sorting = res;
    //    });
    //    this.sortingService.getFiltering('language/movie').then((res: any) => {
    //       this.languages = res;
    //    });
    //    this.contentItems = () => {};
    // }
    this.channelService.getChannels('movie').then((res: any) => {
      console.log("get channel Movie Called...");
      this.channelItems = res;
      this.channelFlag = true;
    });
    this.sortingService.getFiltering('movies').then((res: any) => {
      console.log("get Filtering Movies Called...");
        this.filtering = res;
        this.filterFlag = true;
        this.genreFilter = this.filtering[0].id;
        this.filter = this.genreFilter;
    });
    this.sortingService.getSorting('movies').then((res: any) => {
      console.log("get Sorting  Movies Called...");
        this.sorting = res;
        this.sortFlag = true;
        this.sortBy = this.sorting[0].id;
        for(var f in this.sorting){
          if(this.sorting[f].id == "Release Date") {
            this.sortBy = this.sorting[f].id;
          }
        }
        this.sort = this.sortBy;
    });
    await this.sortingService.getFiltering('language/movie').then((res: any) => {
      console.log("get Filtering  language/Movies Called...");
        this.languages = res;
        this.langFlag = true;
        this.languageFilter = this.languages[0];
        this.language = this.languageFilter;
    });
    await this.sortingService.getAudienceList('movie/audience').then((res: any) => {
      this.audienceList = res;
  });
    if (window.location.pathname == '/content/movies/all') {
       this.dataService.getMediaItems('video', 'movies', 0, 30, '', 'Release Date','','').then((res: any) => {
          this.contentItems = res;
          this.catalog = this.contentItems;
          this.contentFlag = true;
       });
    }
    else {
      //console.log("location", this.location.path());
      this.dataService.getChanneledData('video', 'movies', 0, 30, '', 'Release Date','', window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length),"").then((res: any) => {
        this.contentItems = res;
        this.catalog = this.contentItems;
        this.contentFlag = true;
      });
    }
  }

  getQueryParam(param): any {
    // this.route.queryParams.subscribe(params => {
    //   console.log("queryparam", params.playlist_id);
    //   return params.playlist_id;
    //   //this.playlist_id_param = params.playlist_id;
    // },
    this.route.paramMap.subscribe(params => {
        ///console.log("params", params['channel_name']);
        //console.log("queryparam", params.get('playlist_id'));
        return params.get(param);
        });
  }

  getSelectableMovies() {
    var result = this.catalog.filter((video) => video.selectable == false);
    if (result.length > 0) {
      this.isUpdateAvailable = true;
    }
    else {
      this.isUpdateAvailable = false;
    }
    return this.catalog.filter((video) => video.selectable == true);
  }

  getNonSelectableMovies() {
    var result = this.catalog.filter((video) => video.selectable == false);
    return result;
  }
  
  finished(checkLoading=true) {
    if (checkLoading == true){
       this.increaseLimit();
       this.checkLoading = false;
       //console.log("checkLoading")
    }
  };

  activate() {
    this.isPlaylist = !!this.getQueryParam('playlist_id') ? true : false;
    this.watchDatalimit();
  }

  showList() {
      if (!this.coreservice.showingList) {
         this.coreservice.showingList = true;
      }
  }
  showTiles() {
      if (this.coreservice.showingList) {
         this.coreservice.showingList = false;
      }
  }

  watchDatalimit() {
    this.dataLimit = 30;
    this.increaseLimit();
  }

  increaseLimit() {
    if (this.getMore) {
        var oldLimit = this.dataLimit;
        this.dataLimit += 30;
        this.getMoreItems(oldLimit, 30);
    }
  }
  getMoreItems(skip, take) {
    if (this.getMore) {

       //console.log("window.location.pathname.getMoreItems", window.location.pathname);
        if (!!this.getQueryParam('playlist_id')) {
            return this.playlistService.getPlaylistItems(this.getQueryParam('playlist_id'), 'video', 'movies', skip, take, this.filter, this.sort, this.language)
                .then((res: any) => {
                    if (res.length >= 1) {
                        return this.catalog = this.catalog.concat(res);
                    } else {
                        this.mediadriveService.getMediaDrive();
                        this.getMore = false;
                    }
                });
        } else {
            //var channelname = !!this.getQueryParam('channel_name') ? this.getQueryParam('channel_name') : '';
            var channelname = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)
            if(channelname != '') {
                return this.dataService.getChanneledData('video', 'movies', skip, take, this.filter, this.sort, this.language, channelname,this.audience)
                .then((res) => {
                    if (res.length >= 1) {
                        this.catalog = this.catalog.concat(res);
                        this.finished();
                        //return vm.catalog = vm.catalog.concat(res);
                    } else {
                        this.mediadriveService.getMediaDrive();
                        this.getMore = false;
                    }
                });
            } else {
                return this.dataService.getMediaItems('video', 'movies', skip, take, this.filter, this.sort, this.language,this.audience)
                .then((res) => {

                    if (res.length >= 1) {
                        this.catalog = this.catalog.concat(res);
                        //console.log("res in movie####", this.catalog)
                        this.finished();
                        //return vm.catalog = vm.catalog.concat(res);
                    } else {
                        this.mediadriveService.getMediaDrive();
                        this.getMore = false;
                    }
                });
            }
        }
    }
  }

  filterSelected() {
    this.cleanCall();
  }

  sortSelected() {
    this.cleanCall();
  }

  languageSelected() {
    this.cleanCall();
  }
  selectedData=[];
  audienceChange($event:any)
  {
    if($event.source._selected)
    {
      if($event.source.value=="All")
        this.selectedData.push("");
      else
        this.selectedData.push($event.source.value);
    }
    else
    {
      var index=this.selectedData.indexOf($event.source.value)
      if(index!=-1)
      {
        this.selectedData.splice(index,1);
      }
    }
    this.selectedAudience=this.selectedData.toString();
    this.cleanCall();
  }
  cleanCall() {
    var skip = 0;
    var take = 30;
    this.sort = this.sortBy;
    this.filter = this.genreFilter;
    this.language = this.languageFilter;
    this.dataLimit = 30;
    this.getMore = false;
    this.message = '';
    this.catalog = [];
    this.audience=this.selectedAudience;
    var mediaContainer = document.getElementsByClassName('media-files')[0];
    var mediaListContainer = document.getElementsByClassName('double-small')[0];

    if (!!mediaContainer && !!mediaListContainer) {
        mediaContainer.scrollTop = 0;
        mediaListContainer.scrollTop = 0;
    } else if (!!mediaContainer) {
        mediaContainer.scrollTop = 0;
    }

    this.isLoading = true;

    this.channelService.getChannels('movie').then((res) => {
        return this.channelItems = res;
    })

    if (!!this.getQueryParam('playlist_id')) {
        return this.playlistService.getPlaylistItems(!!this.getQueryParam('playlist_id'), 'video', 'movies', skip, take, this.filter, this.sort, this.language)
            .then((res) => {
                if (res.length >= 1) {
                    this.getMore = true;
                    this.message = '';
                    return this.catalog = res;
                } else {
                    this.getMore = false;
                    this.mediadriveService.getMediaDrive();
                    this.message = 'no_results';
                    return this.catalog = [];
                }
            });
    } else {
        var channelname = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)
        //var channelname = !!this.getQueryParam('channel_name') ? this.getQueryParam('channel_name') : '';
        if(channelname != '') {
            return this.dataService.getChanneledData('video', 'movies', skip, take, this.filter, this.sort, this.language, channelname,this.audience)
            .then((res) => {
                if (res.length >= 1) {
                    this.getMore = true;
                    //vm.coreservice.textloader = false;
                    this.message = '';
                    this.finished();
                    return this.catalog = res;
                } else {
                    this.getMore = false;
                    this.mediadriveService.getMediaDrive();
                    this.message = 'no_results';
                    //vm.coreservice.textloader = false;
                    return this.catalog = [];
                }
            });
        } else {
            return this.dataService.getMediaItems('video', 'movies', skip, take, this.filter, this.sort, this.language,this.audience)
            .then((res) => {
                if (res.length >= 1) {
                    this.getMore = true;
                    //vm.coreservice.textloader = false;
                    this.message = '';
                    this.finished();
                    return this.catalog = res;
                } else {
                    this.getMore = false;
                    this.mediadriveService.getMediaDrive();
                    this.message = 'no_results';
                    //vm.coreservice.textloader = false;
                    return this.catalog = [];
                }
            });
        }

      }

  }

  getDetails(video) {
    var videoId = video.movie_id;
    this.getMovieDetails(video);
  }

  getMovies(videoId) {
    console.log(videoId)
    var url: string = '';
    var params: string = this.getQueryParam('playlist_id');
    console.log(params)
    if (params) {
        url = settings.configuration.env.apiUrl + 'playlist/' + params + '/video/movie/' + videoId;
    } else {
        url = settings.configuration.env.apiUrl + 'content/video/movie/' + videoId;
    }
    console.log(url);
    return this.http
        .get(url)
        .toPromise().then(
          (res: any) => {
            console.log("response");
            return res
          }
        )
  }
  async getMovieDetails(video) {
    var movieDetails;
    if (!this.blockUI) {
      this.blockUI = true;
      await this.getMovies(video.movie_id).then((res: any) => {
        movieDetails = res;
      });
      var dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        movieDetails: movieDetails,
        isUpdateAvailable: video.selectable
      };
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'video-width'];
      let moviedialog = this.dialog.open(MovieDialog, dialogConfig);
      moviedialog.beforeClosed().subscribe(res => {
        this.blockUI = false;
      })
    }
  }

  selectItem(movie, index) {
    var movie_id = movie.movie_id;
    var status = movie.is_selected;
    var movieIndex = index;
    var boolean;

    switch (status) {
        case 0:
            boolean = true;
            break;
        case 4:
            boolean = false;
            break;
        default:
            boolean = true;
            break;
    }

    this.selectItems.addToList(movie_id, 'movie', 'video', boolean, null).then((res) => {
        var newStatus = res.is_selected;
        this.catalog[movieIndex].is_selected = newStatus;
    });
  }
}

angular
  .module('app.video')
  .directive(
    'moviecontent',
    downgradeComponent({component: MoviecontentComponent}) as angular.IDirectiveFactory
  );

